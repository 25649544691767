@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@tailwind base;
body {
	@apply font-['Open_Sans'] overflow-hidden;
}
@tailwind components;
.modal {
	@apply
	hidden 
	absolute 
	right-0 
	inset-y-0 
	my-2.5 
	mx-2.5 
	w-auto 
	h-auto 
	rounded-xl 
	bg-white 
	overflow-hidden
	shadow-[0_2px_4px]
	dark:bg-gradient-to-b
	dark:from-background-dark
	dark:to-accent
	shadow-accent
	dark:shadow-none
	xl:right-0
	xl:left-0
	xl:top-0
	xl:my-10
	xl:mx-10;
}

@tailwind utilities;
.menu--closed {
	@apply hidden;
}

.modal--open {
	@apply flex flex-col;
}

